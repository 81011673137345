const $ = require('jquery');
const slick = require('slick-carousel');
module.exports = function() {
    const works_slider = $('[data-type="works-slider"]');
    if(works_slider.length == 0) return;
    if(window.detect == "sp") {
        works_slider.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<img class="arrow_left" src="../images/common/arrow_left.svg" alt="">',
            nextArrow: '<img class="arrow_right" src="../images/common/arrow_right.svg" alt="">',
            centerMode: true,
            centerPadding: '35px',
        });
    }
}
/*sliderカウンター*/
$('.slider').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    $('.slick-num .nowcnt').text(i);
    $('.slick-num .allcnt').text(slick.slideCount);
});
