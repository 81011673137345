export const cmsHeadlineInclude = () => {
    const holders = $('.cmsHeadlineInclude');
    if(!holders.length) return;

    holders.each(function() {
        const endpoint = $(this).attr('data-endpoint');
        if(!endpoint) return;

        $(this).load(endpoint);
    });
}
