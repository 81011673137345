import {drawer} from "./components/drawer";
import { fadeIn } from "./components/fadein";
import { line_clamp } from "./components/line_clamp";
import works_slider from "./components/works_slider";
import {cmsDetailPagination} from "./components/cmsDetailPagination";
import {cmsHeadlineInclude} from "./components/cmsHeadlineInclude";
import { yt_btn } from "./components/yt_btn";

window.addEventListener('DOMContentLoaded', function() {
    drawer();
    fadeIn();
    line_clamp();
    works_slider();
    cmsDetailPagination();
    cmsHeadlineInclude();
    yt_btn();
});
