 export const yt_btn = () => {
  $(function(){
    $('#yt_wrap a').on('click' , function(e){
        e.preventDefault();//リンクの停止
        $(this).parent().html('<iframe src="https://www.youtube.com/embed/Fh4b9JB5ukg?autoplay=1&rel=0&controls=0"></iframe>');
      });
  });
}


