 export const fadeIn = () => {
 	const targets = document.querySelectorAll(".fadein")
 	if(!targets) return



	const windowHeight = window.parent.screen.height
 	targets.forEach((target) => {
 		window.addEventListener("scroll", () => {
 			const targetOffsetTop = target.offsetTop
 			const scroll = window.pageYOffset;

 			if (scroll > targetOffsetTop - windowHeight + 300) {
 				target.classList.add("scrollin")
 			}
 		})
 	})
 }


